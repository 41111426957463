import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';

import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { EventManagerService } from './pages/general/blog/event-manager.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedCommonModule } from './common/shared.module';
import { TranslateModule, TranslateLoader, TranslateService, TranslateStore } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalModule, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmRouterPopupComponent } from './common/components/confirm-router-popup/confirm-router-popup.component';
import { StateService } from './extend-code/state.service';

declare let $: any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterOutlet, HttpClientModule, SharedCommonModule, TranslateModule, NgbModalModule],
  providers: [TranslateService, TranslateStore],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked {
  categories: any[] = [];
  language: string = '';
  public eventSubscribers?: Subscription[] = [];
  constructor(
    private eventManager: EventManagerService,
    private translate: TranslateService,
    private stateService: StateService,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: object,

  ) {
    
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
    }
    if (typeof window === 'undefined') {
      return;
    }
    this.route.queryParams.subscribe((params: any) => {
      if (typeof window === 'undefined') {
        return;
      }
      if (params.utm_source === 'accesstrade' && params.aff_sid) {
        localStorage.setItem('utm_source', params.utm_source);
        localStorage.setItem('aff_sid', params.aff_sid);
      }
      if (params['thirdAppId']) {
        localStorage.setItem('thirdAppId', params['thirdAppId']);
        localStorage.setItem('referralCode', '');
        localStorage.setItem('agencyPartnerCode', '');
        localStorage.setItem('partnerId', '');
      }
    });
    this.eventSubscribers = [
      this.eventManager.subscribe("change-language", (res: any) => {
        this.language = (res.content.language || "VI").toLowerCase();
        this.translate.use(this.language);
        if (typeof window !== 'undefined') {
          localStorage.setItem('language', this.language);
          this.stateService.changeLanguage(this.language);
          const file = this.language === "vi" ? "vi_VN" : "en_US";
          localStorage.setItem('language-file', file);
        }
      }),
      this.eventManager.subscribe("confirm-router-change", (res: any) => {
        localStorage.setItem('nextURL', res.content.url);
        this.openConfirmRouterPopupComponent();
      }),
    ];
    if (typeof window !== 'undefined') {
      this.translate.addLangs(['en', 'vi']);
      this.language = localStorage.getItem('language') || 'vi';
      const file = this.language === "vi" ? "vi_VN" : "en_US";
      localStorage.setItem('language-file', file);
    }
  }
  ngAfterViewInit(): void {
    if (typeof window !== 'undefined') {
      $('body').on('click', (e: any) => {
        this.eventManager.broadcast({
          name: 'body-click',
          content: {
            event: e
          }
        });
      });
    }
  }
  ngAfterViewChecked(): void {
    if (typeof window === 'undefined') {
      return;
    }
    this.translate.addLangs(['en', 'vi']);
    const language = localStorage.getItem('language') || '';
    this.translate.use(language);
    this.eventManager.broadcast({
      name: "change-language",
      content: {
        language,
      },
    });
  }
  openConfirmRouterPopupComponent() {
    const options: NgbModalOptions = {};
    options.centered = true;
    options.windowClass = "confirm-router-modal";
    const modalRef = this.modalService.open(
      ConfirmRouterPopupComponent,
      options
    );
  }
}
