import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StateService {
  private languageState = new Subject<any>();
  constructor(
    ) {
  }
  changeLanguage(language: any) {
    this.languageState.next(language);
  }
  getLanguageState(): Observable<any> {
    return this.languageState.asObservable();
  }
}
